import IconCalendar from "assets/icon-calendar.svg";
import IconCheck from "assets/icon-check.svg";
import IconEdit from "assets/icon-edit.svg";
import IconEuro from "assets/icon-euro.svg";
import IconUnion from "assets/icon-union.svg";
import CalculationPriceEmployee from "components/calculation/calculationPriceEmployee";
import CalculationPriceEmployer from "components/calculation/calculationPriceEmployer";
import CalculationSettings from "components/calculation/calculationSettings";
import Footer from "components/footer/footer";
import Button from "components/material/button/button";
import { DataLayerEvents, DataLayerNames } from "constants/dataLayerEvent";
import { SiteUrls } from "constants/siteUrl";
import { StorageItems } from "constants/storageItems";
import CalculatorType from "enums/calculatorType";
import LoaderTypes from "enums/loaderTypes";
import { languages } from "i18n/languages";
import TranslationMapper from "i18n/mapper";
import ibanValidator from "iban";
import { produce } from "immer";
import {
  IRegistrationWizardAddressRequestProps,
  IRegistrationWizardCalculationRequestProps,
  IRegistrationWizardHasAgreedToRequestProps,
  IRegistrationWizardPaymentInformationRequest,
  IRegistrationWizardPersonalInformationRequest,
  IRegistrationWizardRegisterRequestProps,
} from "interfaces/IRegistrationWizard";
import { sha256 } from "js-sha256";
import { CleaningFrequency } from "pages/calculator/enums/cleaningFrequency";
import LanguageProvider from "providers/languageProvider";
import { RoutingPath } from "providers/routingProvider";
import SessionStorageProvider from "providers/sessionProvider";
import { ChangeEvent, Component } from "react";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";
import { connect } from "react-redux";
import { createRegisterAsync, setUpdateDataSummary } from "store/actions/registrationActions";
import { RootState } from "store/reducers/rootReducer";
import { RecaptchaUtil } from "utils/recaptchaUtil";
import { ScriptUtil } from "utils/scriptUtil";
import { transformStringToHyperlink } from "utils/stringUtils";

import { ISummaryDispatchProps, ISummaryProps, ISummaryStateProps } from "./interfaces/ISummaryProps";
import { ISummaryState } from "./interfaces/ISummaryState";

class Summary extends Component<ISummaryProps, ISummaryState> {
  private isSummaryStartPushed = false;

  public constructor(props: ISummaryProps) {
    super(props);

    this.state = this.defaultState();

    this.toStep = this.toStep.bind(this);
    this.onIBANChange = this.onIBANChange.bind(this);
    this.onTermsOfDeliveryChange = this.onTermsOfDeliveryChange.bind(this);
    this.onPrivacyPolicyChange = this.onPrivacyPolicyChange.bind(this);
    this.onDirectDebtChange = this.onDirectDebtChange.bind(this);
  }

  private defaultState(): ISummaryState {
    const state: ISummaryState = {
      termsOfDelivery: this.props.summary.termsOfDelivery,
      payment: this.props.summary.payment,
      isFormValidated: this.props.isFormValidated,
      isIBANValid: this.props.isIBANValid,
      isValid: this.props.isValid,
      directDebt: this.props.summary.directDebt, //agreed to
      privacyPolicy: this.props.summary.privacyPolicy,
    };

    return state;
  }

  public componentDidMount(): void {
    // GTM - Data Layer - when page is not refreshed but came from previous step (./personal-information)
    // then push data layer
    if (this.props.calculation.costEmployee !== 0) {
      this.dataLayerPushSummaryStart();
    }
  }

  public componentDidUpdate(prevProps: Readonly<ISummaryProps>): void {
    // GTM - Data Layer - when page is refreshed then push data layer
    if (
      prevProps.calculation.costEmployee !== this.props.calculation.costEmployee ||
      prevProps.calculation.costEmployer !== this.props.calculation.costEmployer
    ) {
      this.dataLayerPushSummaryStart();
    }
  }

  private get data(): ISummaryState {
    return {
      payment: this.state.payment,
      isFormValidated: this.state.isFormValidated,
      isIBANValid: this.state.isIBANValid,
      isValid: this.state.isValid,
      directDebt: this.state.directDebt,
      termsOfDelivery: this.state.termsOfDelivery,
      privacyPolicy: this.state.privacyPolicy,
    };
  }

  private get dataCreateRegister(): IRegistrationWizardRegisterRequestProps {
    const address: IRegistrationWizardAddressRequestProps = {
      city: this.props.address.city,
      houseNumber: this.props.address.houseNumber,
      houseNumberAddition: this.props.zipCode.houseNumberAddition,
      street: this.props.address.street,
      zipCode: this.props.address.zipCode,
    };

    const calculationRequest: IRegistrationWizardCalculationRequestProps = {
      cleaningFrequency: this.props.calculator.calculation.cleaningFrequency,
      houseType: this.props.calculator.calculation.houseType,
      houseSurface: this.props.calculator.calculation.houseSurface,
      includeIroning: this.props.calculator.calculation.includeIroning,
      selectedWeeklyHours: this.props.calculator.calculation.selectedWeeklyHours,
      selectedWeeklyIroningHours: this.props.calculator.calculation.selectedWeeklyIroningHours,
      discountCode: this.props.calculator.calculation.discountCode,
      useSelectedWeeklyHoursInCalculation: this.props.calculator.calculation.useSelectedWeeklyHoursInCalculation,
    };

    const hasAgreedTo: IRegistrationWizardHasAgreedToRequestProps = {
      directDebt: this.data.directDebt,
      termsOfDelivery: this.data.termsOfDelivery,
      privacyPolicy: this.data.privacyPolicy,
    };

    const personalInfo: IRegistrationWizardPersonalInformationRequest = {
      firstName: this.props.personalInformation.firstName,
      prefix: this.props.personalInformation.prefix,
      lastName: this.props.personalInformation.lastName,
      phoneNumber: this.props.personalInformation.phoneNumber,
      emailAddress: this.props.personalInformation.emailAddress,
    };

    const paymentInfo: IRegistrationWizardPaymentInformationRequest = {
      // if paymentmethod is not automatic collection, then iban won't be sent to backend
      iban: this.data.payment.automaticCollection ? this.data.payment.iban : "",
      automaticCollection: this.data.payment.automaticCollection,
    };

    const preferredLanguage = LanguageProvider.language;

    const data = {
      address,
      calculationRequest,
      captchaToken: "", // updated/added in this.createRegisterAsync()
      hasAgreedTo,
      personalInfo,
      paymentInfo,
      preferredLanguage,
    };

    return data;
  }

  private get isFormValidated(): boolean {
    return this.state.isFormValidated;
  }

  private get isDiscountCodePercentageEnabled(): boolean {
    return this.props.calculator.discount.calculatorType === CalculatorType.Default;
  }

  private dataLayerPushSummaryStart() {
    if (!this.isSummaryStartPushed) {
      this.isSummaryStartPushed = true; // prevent pushing multiple times
      ScriptUtil.dataLayerPush({
        active_on_address: "Yes",
        coupon: this.props.calculator.calculation.discountCode,
        currency: "EUR",
        event: DataLayerEvents.Step3SummaryStart,
        frequency: CleaningFrequency[this.props.calculator.calculation.cleaningFrequency],
        ironing_service: this.props.calculator.calculation.includeIroning ? "Yes" : "No",
        step: 3,
        step_name: DataLayerNames.Step3Summary,
        time_in_hours: this.props.calculator.calculation.selectedWeeklyHours,
        time_in_minutes: this.props.calculator.calculation.selectedWeeklyHours * 60,
        value: this.props.calculation.costEmployee,
      });
    }
  }

  private setStoreData(): void {
    this.props.updateDataSummary(this.data);

    // remove manually entered values from session storage (when page is refreshed manually set checkboxes)
    const dataSession = {
      ...this.data,
      directDebt: undefined,
      privacyPolicy: undefined,
      termsOfDelivery: undefined,
      isFormValidated: undefined,
    };
    SessionStorageProvider.set(StorageItems.Summary, dataSession);
  }

  public toStep(path: string): void {
    this.props.history.push({
      pathname: path,
    });
  }

  private onIBANChange(event: ChangeEvent<HTMLInputElement>): void {
    // Custom iban check
    const isIBANValid = ibanValidator.isValid(event.target.value);
    event.target.setCustomValidity(isIBANValid ? "" : "error");
    this.setState(
      (prevState) => ({
        payment: {
          ...prevState.payment,
          iban: event.target.value,
        },
        isIBANValid,
      }),
      this.handleSaveSettings
    );
  }

  private onDirectDebtChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        directDebt: event.target.checked,
      },
      this.handleSaveSettings
    );
  }

  private onTermsOfDeliveryChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        termsOfDelivery: event.target.checked,
      },
      this.handleSaveSettings
    );
  }

  private onPrivacyPolicyChange(event: ChangeEvent<HTMLInputElement>): void {
    this.setState(
      {
        privacyPolicy: event.target.checked,
      },
      this.handleSaveSettings
    );
  }

  private handleSaveSettings(): void {
    this.setStoreData();
  }

  private validateRecaptchaProps(): void {
    if (this.props.googleReCaptchaProps?.executeRecaptcha != null) {
      return;
    }

    if (RecaptchaUtil.recaptchaValidationRetryCount < RecaptchaUtil.maxRecaptchaValidationRetryCount) {
      // Sometimes the recaptcha is still loading in the DOM, we retry to compensate for this.
      // This process can't be completely handled by util, because props won't update in timeout
      window.setTimeout(this.createRegisterAsync.bind(this), 2000);
      RecaptchaUtil.increaseRetryCount();
      return;
    }

    RecaptchaUtil.throwRecaptchaPropsError("Success.completeRegistration");
    RecaptchaUtil.resetRetryCounter();

    this.toStep(RoutingPath.home);
  }

  private async createRegisterAsync(): Promise<void> {
    const dataCreateRegister = this.dataCreateRegister;

    if (!this.props.googleReCaptchaProps?.executeRecaptcha) {
      this.validateRecaptchaProps();
      return;
    }

    const captchaToken = await this.props.googleReCaptchaProps.executeRecaptcha();
    dataCreateRegister.captchaToken = captchaToken;

    this.props.createRegisterAsync(dataCreateRegister, () => {
      ScriptUtil.dataLayerPush({
        active_on_address: "Yes",
        coupon: this.props.calculator.calculation.discountCode,
        currency: "EUR",
        event: DataLayerEvents.Step3SummarySubmitted,
        frequency: CleaningFrequency[this.props.calculator.calculation.cleaningFrequency],
        ironing_service: this.props.calculator.calculation.includeIroning ? "Yes" : "No",
        step: 3,
        step_name: DataLayerNames.Step3Summary,
        time_in_hours: this.props.calculator.calculation.selectedWeeklyHours,
        time_in_minutes: this.props.calculator.calculation.selectedWeeklyHours * 60,
        value: this.props.calculation.costEmployee,

        enhanced_conversion_data: {
          address: {
            city: sha256(this.props.address.city),
            country: sha256("NL"),
            postal_code: sha256(this.props.address.zipCode),
            region: "",
            street: sha256(this.props.address.street),
          },
        },
      });

      this.toStep(RoutingPath.whatsNext);
    });
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private handleSubmit(e: any): void {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ isFormValidated: true });

    if (e.target.checkValidity()) {
      this.setStoreData();

      this.createRegisterAsync();
    }
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  public renderPersonalInfo(): JSX.Element {
    return (
      <div className="mb-lg-md">
        <div className="row mb-2">
          <div className="col d-flex justify-content-between">
            <div>
              <h2 className="mb-3">
                {LanguageProvider.t(TranslationMapper.summary.personal_info.header)}
                <img src={IconCheck} alt="" className="icon icon__md ms-2" />
              </h2>
              <p>
                {this.props.personalInformation.firstName}{" "}
                {this.props.personalInformation.prefix && `${this.props.personalInformation.prefix} `}
                {this.props.personalInformation.lastName}
                <br />
                {this.props.personalInformation.emailAddress}
                <br />
                {this.props.personalInformation.phoneNumber}
              </p>
            </div>
            <div>
              <Button
                className="btn-link btn-sm btn--summary__edit-h2"
                label={LanguageProvider.t(TranslationMapper.global.edit)}
                iconTextToggle={true}
                icon={IconEdit}
                onClick={(): void => this.toStep(RoutingPath.personalInformation)}
              ></Button>
            </div>
          </div>
        </div>
        <div className="row mb-2">
          <div className="col d-flex justify-content-between">
            <div>
              <h3>{LanguageProvider.t(TranslationMapper.summary.cleaning_address.header)}</h3>
              {this.props.address?.zipCode != null && (
                <p className="mb-0">
                  {this.props.address?.street} {this.props.address?.houseNumber}
                  {this.props.zipCode?.houseNumberAddition}
                  <br />
                  {this.props.address?.zipCode} {this.props.address?.city}
                </p>
              )}
            </div>
            <div>
              <Button
                className="btn-link btn-sm btn--summary__edit-h3"
                label={LanguageProvider.t(TranslationMapper.global.edit)}
                iconTextToggle={true}
                icon={IconEdit}
                onClick={(): void => this.toStep(RoutingPath.home)}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public renderSubscription(): JSX.Element {
    return (
      <div className="mb-lg-md">
        <div className="row mb-2">
          <div className="col d-flex justify-content-between">
            <div>
              <h2 className="mb-3">
                {LanguageProvider.t(TranslationMapper.summary.subscription.header)}
                <img src={IconCheck} alt="" className="icon icon__md ms-2" />
              </h2>

              <div className="summary__calculation-settings">
                <CalculationSettings />
              </div>
            </div>

            <div>
              <Button
                className="btn-link btn-sm btn--summary__edit-h3"
                label={LanguageProvider.t(TranslationMapper.global.edit)}
                iconTextToggle={true}
                icon={IconEdit}
                onClick={(): void => this.toStep(RoutingPath.home)}
              ></Button>
            </div>
          </div>
        </div>
        <div className="col d-none d-lg-flex justify-content-between">
          <CalculationPriceEmployee isReadDataCalculationAllowed={false} isEveryLongShown={true} />
          {this.isDiscountCodePercentageEnabled && (
            <CalculationPriceEmployer isEmployerTextShown={false} isEveryLongShown={true} isColorGray={true} />
          )}
        </div>
      </div>
    );
  }

  private isValidPaymentInformation(): boolean {
    if (this.state.payment.automaticCollection === false) {
      return true;
    }
    if (this.state.payment.automaticCollection === true && this.state.isIBANValid && this.state.directDebt === true) {
      return true;
    }
    return false;
  }

  public renderDebit(): JSX.Element {
    return (
      <div className="mb-lg-md">
        <div className="row mb-md">
          <div className="col">
            <h2 className="mb-3">
              {LanguageProvider.t(TranslationMapper.summary.payment_info.header)}
              {this.isValidPaymentInformation() && <img src={IconCheck} alt="" className="icon icon__md ms-2" />}
            </h2>
            {this.renderPaymentMethods()}
            {/* label payment of subscription */}
            <label className="col-12 form-check-label d-flex justify-content-start align-items-center mb-3">
              <img src={IconCalendar} className="icon icon__md" alt="" />
              <div className="ps-2">
                <p className="mb-0 d-flex align-items-center"></p>
                {this.state.payment.automaticCollection
                  ? LanguageProvider.t(TranslationMapper.summary.payment_info.automatic_collection.labels.text1)
                  : LanguageProvider.t(TranslationMapper.summary.payment_info.invoice.labels.text1)}
              </div>
            </label>
            {/* label billing of subscription */}
            <label className="col-12 form-check-label d-flex justify-content-start align-items-center mb-3">
              <img src={IconEuro} className="icon icon__md" alt="" />
              <div className="ps-2">
                <p className="mb-0 d-flex align-items-center">
                  {this.state.payment.automaticCollection
                    ? LanguageProvider.t(TranslationMapper.summary.payment_info.automatic_collection.labels.text2)
                    : LanguageProvider.t(TranslationMapper.summary.payment_info.invoice.labels.text2)}
                </p>
              </div>
            </label>
            {/* label cancelation of subscription */}
            <label className="col-12 form-check-label d-flex justify-content-start align-items-center mb-3">
              <img src={IconUnion} className="icon icon__md" alt="" />
              <div className="ps-2">
                <p className="mb-0 d-flex align-items-center">
                  {this.state.payment.automaticCollection
                    ? LanguageProvider.t(TranslationMapper.summary.payment_info.automatic_collection.labels.text3)
                    : LanguageProvider.t(TranslationMapper.summary.payment_info.invoice.labels.text3)}
                </p>
              </div>
            </label>
            {this.state.payment.automaticCollection && this.renderIban()}
          </div>
        </div>
      </div>
    );
  }

  public renderPaymentMethods(): JSX.Element {
    return (
      <div className="row mb-lg-md">
        <div className="d-flex flex-column flex-md-row">
          <div className="form-check form-check-inline d-flex align-items-center mb-3 mb-md-0 w-100">
            <input
              className="form-check-input"
              type="radio"
              name="payment"
              id="invoice"
              value="false"
              required
              checked={this.state.payment.automaticCollection === false}
              onChange={(): void => this.handleAutomaticCollection(false)}
            />
            <label className="form-check-label" htmlFor="invoice">
              {LanguageProvider.t(TranslationMapper.summary.payment_info.invoice.description)}
            </label>
          </div>
          <div className="form-check form-check-inline d-flex align-items-center w-100">
            <input
              className="form-check-input"
              type="radio"
              name="payment"
              id="automatic-collection"
              checked={this.state.payment.automaticCollection === true}
              onChange={(): void => this.handleAutomaticCollection(true)}
            />
            <label className="form-check-label" htmlFor="automatic-collection">
              {LanguageProvider.t(TranslationMapper.summary.payment_info.automatic_collection.description)}
            </label>
          </div>
        </div>
      </div>
    );
  }

  public renderIban(): JSX.Element {
    return (
      <>
        <div className="mb-4 row">
          <label htmlFor="iban" className="col-3 col-sm-2 col-form-label">
            {LanguageProvider.t(TranslationMapper.summary.payment_info.automatic_collection.iban.label)}*
          </label>
          <div className="col">
            <input
              type="text"
              name="iban"
              className="form-control"
              id="iban"
              required
              value={this.state.payment.iban}
              onChange={this.onIBANChange}
              disabled={this.props.isCreatingAccountLoading}
            />
          </div>
        </div>
        <div className="mb-3 row">
          <div className="col">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                required
                id="permission_direct_debt"
                checked={this.state.directDebt === true}
                onChange={this.onDirectDebtChange}
                disabled={this.props.isCreatingAccountLoading}
              />
              <label className="form-check-label" htmlFor="permission_direct_debt">
                {LanguageProvider.t(TranslationMapper.summary.payment_info.automatic_collection.iban.checkbox.label)}*
              </label>
            </div>
          </div>
        </div>
      </>
    );
  }

  private handleAutomaticCollection(value: boolean): void {
    const state = produce(this.state, (draft) => {
      draft.payment.automaticCollection = value;
      // if user changes payment method, reset 'agree to direct debt'
      // user must knowingly agree to direct debit
      draft.directDebt = false;
    });
    this.setState(state, this.handleSaveSettings);
  }

  public renderTermsCondition(): JSX.Element {
    const textWithUrlPrivacy = transformStringToHyperlink(
      LanguageProvider.t(TranslationMapper.summary.terms.privacy.label),
      LanguageProvider.t(TranslationMapper.summary.terms.privacy.link_label),
      LanguageProvider.language === languages[0].key ? SiteUrls.en.PrivacyPolicy : SiteUrls.PrivacyPolicy
    );
    const textWithUrlConditions1 = transformStringToHyperlink(
      LanguageProvider.t(TranslationMapper.summary.terms.conditions.part1.label),
      LanguageProvider.t(TranslationMapper.summary.terms.conditions.part1.link_label),
      LanguageProvider.language === languages[0].key ? SiteUrls.en.TermsOfService : SiteUrls.TermsOfService
    );
    const textWithUrlConditions2 = transformStringToHyperlink(
      LanguageProvider.t(TranslationMapper.summary.terms.conditions.part2.label),
      LanguageProvider.t(TranslationMapper.summary.terms.conditions.part2.link_label),
      LanguageProvider.language === languages[0].key ? SiteUrls.en.TermsOfPlatform : SiteUrls.TermsOfPlatform
    );
    return (
      <div className="mb-lg-md">
        <div className="row mb-2">
          <div className="col">
            <h2 className="mb-3">
              {LanguageProvider.t(TranslationMapper.summary.terms.header)}
              {this.state.privacyPolicy && this.state.termsOfDelivery && (
                <img src={IconCheck} alt="" className="icon icon__md ms-2" />
              )}
            </h2>
          </div>

          <div className="mb-3 row">
            <div className="col">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="privacyPolicy"
                  required
                  onChange={this.onPrivacyPolicyChange}
                  checked={this.state.privacyPolicy === true}
                  disabled={this.props.isCreatingAccountLoading}
                />
                <label className="form-check-label" htmlFor="privacyPolicy">
                  {textWithUrlPrivacy}*
                </label>
              </div>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="termsOfDelivery"
                  required
                  onChange={this.onTermsOfDeliveryChange}
                  checked={this.state.termsOfDelivery === true}
                  disabled={this.props.isCreatingAccountLoading}
                />
                <label className="form-check-label" htmlFor="termsOfDelivery">
                  {textWithUrlConditions1}
                  {textWithUrlConditions2}*
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public render(): JSX.Element {
    const _icon = this.props.isCreatingAccountLoading ? false : undefined;

    return (
      <form
        className={`needs-validation${this.isFormValidated ? " was-validated" : ""}`}
        id="form-summary"
        noValidate
        onSubmit={(e): void => this.handleSubmit(e)}
      >
        <div className="summary">
          <div className="row">
            <div className="col">
              <h1 className="mb-lg-lg">{LanguageProvider.t(TranslationMapper.summary.title)}</h1>
            </div>
          </div>
          {this.renderPersonalInfo()}
          <hr />
          {this.renderSubscription()}
          <hr />
          {this.renderDebit()}
          <hr />
          {this.renderTermsCondition()}

          <Footer
            contentStartButton={{
              onClick: () => this.toStep(RoutingPath.personalInformation),
              disabled: this.props.isCreatingAccountLoading,
            }}
            contentEndButton={{
              label: LanguageProvider.t(TranslationMapper.summary.next),
              icon: _icon,
              type: "submit",
              isLoading: this.props.isCreatingAccountLoading,
              disabled: this.props.isCreatingAccountLoading,
            }}
          />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state: RootState): ISummaryStateProps => ({
  address: state.registrationState.address,
  calculation: state.registrationState.calculation,
  calculator: state.registrationState.calculator,
  isCreatingAccountLoading: state.loaderState.loaders.some((l) => l === LoaderTypes.AccountCreating),
  personalInformation: state.registrationState.personalInformation,
  summary: state.registrationState.summary,
  zipCode: state.registrationState.zipCode,
});

const mapDispatchToProps: ISummaryDispatchProps = {
  updateDataSummary: setUpdateDataSummary,
  createRegisterAsync: createRegisterAsync,
};

export default withGoogleReCaptcha(connect(mapStateToProps, mapDispatchToProps)(Summary));
