import { fetchedAccountRegistrationError } from "./actions/accountActions";
import { updatedModalInfoShown, updatedModalShareShown } from "./actions/asideActions";
import { updatedErrorData } from "./actions/errorActions";
import { fetchedFeatureFlags } from "./actions/featureFlagActions";
import { loadingFinish, loadingStart } from "./actions/loaderActions";
import {
  fetchedAddress,
  fetchedAddressExists,
  fetchedOffer,
  fetchedOfferAdvice,
  fetchedZipCodeValid,
  initialRegistraionStateFromLocalStorage,
  updatedDataCalculator,
  updateDataCalculatorAdvice as updatedDataCalculatorAdvice,
  updateDataCalculatorAdviceEstimatedWeeklyHours as updatedDataCalculatorAdviceEstimatedWeeklyHours,
  updatedDataCalculatorCalculation,
  updatedDataCalculatorSelectedWeeklyHours,
  updatedDataCalculatorValid,
  updatedDataCleanerHourlyRate,
  updatedDataDiscount,
  updatedDataDiscountCode,
  updatedDataPersonalInformation,
  updatedDataSummary,
  updatedModalCalculatorAdviceShown,
  updatedModalSendCalculationEmailShown,
  updatedZipCodeData,
} from "./actions/registrationActions";
import { initialUnitPriceStateFromLocalStorage } from "./actions/unitPriceActions";

export enum ActionTypes {
  FETCHED_ACCOUNT_ERROR = "FETCHED_ACCOUNT_ERROR",
  FETCHED_ADDRESS = "FETCHED_ADDRESS",
  FETCHED_CALCULATOR_ADVICE_OFFER_APPLY = "FETCHED_CALCULATOR_ADVICE_OFFER_APPLY",
  FETCHED_FEATUREFLAGS = "FETCHED_FEATUREFLAGS",
  FETCHED_OFFER = "FETCHED_OFFER",
  FETCHED_OFFER_ADVICE = "FETCHED_OFFER_ADVICE",
  FETCHED_ZIP_CODE_VALID = "FETCHED_ZIP_CODE_VALID",
  FETCHED_ADDRESS_EXISTS = "FETCHED_ADDRESS_EXISTS",
  INITIALIZE_REGISTRATION_STATE_FROM_LOCAL_STORAGE = "INITIALIZE_REGISTRATION_STATE_FROM_LOCAL_STORAGE",
  INITIALIZE_UNIT_PRICE_STATE_FROM_LOCAL_STORAGE = "INITIALIZE_UNIT_PRICE_STATE_FROM_LOCAL_STORAGE",
  LOADING_START = "LOADING_START",
  LOADING_FINISH = "LOADING_FINISH",
  UPDATED_DATA_CALCULATOR = "UPDATED_DATA_CALCULATOR",
  UPDATED_DATA_CALCULATOR_CALCULATION = "UPDATED_DATA_CALCULATOR_CALCULATION",
  UPDATED_DATA_CALCULATOR_ADVICE = "UPDATED_DATA_CALCULATOR_ADVICE_KEY",
  UPDATED_DATA_CALCULATION_ADVICE_ESTIMATED_WEEKLY_HOURS = "UPDATED_DATA_CALCULATOR_ADVICE_ESTIMATED_WEEKLY_HOURS",
  UPDATED_DATA_CALCULATOR_SELECTED_WEEKLY_HOURS = "UPDATED_DATA_CALCULATOR_SELECTED_WEEKLY_HOURS",
  UPDATED_DATA_CALCULATOR_VALID = "UPDATED_DATA_CALCULATOR_VALID",
  UPDATED_DATA_CLEANER_HOURLY_RATE = "UPDATED_DATA_CLEANER_HOURLY_RATE",

  UPDATED_DATA_DISCOUNT = "UPDATED_DATA_DISCOUNT",
  UPDATED_DATA_DISCOUNT_CODE = "UPDATED_DATA_DISCOUNT_CODE",
  UPDATED_DATA_ERROR = "UPDATED_DATA_ERROR",
  UPDATED_DATA_PERSONAL_INFORMATION = "UPDATED_DATA_PERSONAL_INFORMATION",
  UPDATED_DATA_SUMMARY = "UPDATED_DATA_SUMMARY",
  UPDATED_MODAL_INFO_SHOWN = "UPDATED_MODAL_INFO_SHOWN",
  UPDATED_MODAL_CALCULATOR_ADVICE_SHOWN = "UPDATED_MODAL_CALCULATOR_ADVICE_SHOWN",
  UPDATED_MODAL_SEND_CALCULATION_EMAIL_SHOWN = "UPDATED_MODAL_SEND_CALCULATION_EMAIL_SHOWN",
  UPDATED_MODAL_SHARE_SHOWN = "UPDATED_MODAL_SHARE_SHOWN",
  UPDATED_ZIP_CODE_DATA = "UPDATED_ZIP_CODE_DATA",
}

export type ActionTypesCollection =
  | ReturnType<typeof fetchedAccountRegistrationError>
  | ReturnType<typeof fetchedAddress>
  | ReturnType<typeof fetchedFeatureFlags>
  | ReturnType<typeof fetchedOffer>
  | ReturnType<typeof fetchedOfferAdvice>
  | ReturnType<typeof fetchedZipCodeValid>
  | ReturnType<typeof fetchedAddressExists>
  | ReturnType<typeof initialRegistraionStateFromLocalStorage>
  | ReturnType<typeof initialUnitPriceStateFromLocalStorage>
  | ReturnType<typeof updatedErrorData>
  | ReturnType<typeof loadingFinish>
  | ReturnType<typeof loadingStart>
  | ReturnType<typeof updatedDataCalculator>
  | ReturnType<typeof updatedDataCalculatorAdvice>
  | ReturnType<typeof updatedDataCalculatorAdviceEstimatedWeeklyHours>
  | ReturnType<typeof updatedDataCalculatorCalculation>
  | ReturnType<typeof updatedDataCalculatorValid>
  | ReturnType<typeof updatedDataCalculatorSelectedWeeklyHours>
  | ReturnType<typeof updatedDataCleanerHourlyRate>
  | ReturnType<typeof updatedDataDiscount>
  | ReturnType<typeof updatedDataDiscountCode>
  | ReturnType<typeof updatedDataPersonalInformation>
  | ReturnType<typeof updatedDataSummary>
  | ReturnType<typeof updatedModalCalculatorAdviceShown>
  | ReturnType<typeof updatedModalInfoShown>
  | ReturnType<typeof updatedModalSendCalculationEmailShown>
  | ReturnType<typeof updatedModalShareShown>
  | ReturnType<typeof updatedZipCodeData>;
