export const StorageItems = {
  AccountRegistration: "zizo.account-registration", // Session Storage - only needed in current session
  Address: "zizo.address",
  Calculation: "zizo.calculation",
  Calculator: "zizo.calculator",
  Language: "zizo.language",
  PersonalInformation: "zizo.personal-information", // Session Storage - data may not be stored in local storage
  PaymentInformation: "zizo.payment-information", // Session Storage - data may not be stored in local storage
  Summary: "zizo.summary", // Session Storage - data may not be stored in local storage
  UnitPrice: "zizo.unit-price", // Session Storage - data may not be stored in local storage
  ZipCode: "zizo.zip-code", // Session Storage - data may not be stored in local storage
  ZipCodeIsChecked: "zizo.zipcode.zip-code-is-checked", // Session Storage - only needed to check when in current session
  AddressExists: "zizo.zipcode.address-exists", // Session Storage - only needed to check when in current session
};
